// src/components/Formulaire.js

import React, { useState } from 'react';
import { db } from '../firebase'; // Assurez-vous d'importer votre fichier de configuration
import { collection, addDoc } from 'firebase/firestore'; // Importez les méthodes nécessaires

const Formulaire = () => {
  const [prenom, setPrenom] = useState('');
  const [table, setTable] = useState('');
  const [email, setEmail] = useState('');
  const [erreur, setErreur] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation des données
    if (!prenom || !email || !table) {
      setErreur('Tous les champs sont requis.');
      return;
    }
    if (table < 1 || table > 100) {
      setErreur('Le numéro de table doit être compris entre 1 et 100.');
      setTable(''); // Réinitialise la valeur
      return;
    }

    // Réinitialisez les erreurs
    setErreur('');

    try {
      // Enregistrement dans Firestore
      const docRef = await addDoc(collection(db, 'clients'), {
        prenom: prenom,
        table: parseInt(table, 10), // Assurez-vous que c'est un entier
        email: email,
      });
      console.log('Document écrit avec ID: ', docRef.id);
      setSuccess(true); // Mettre à jour le succès pour afficher le message de confirmation
      // Réinitialiser le formulaire
      setPrenom('');
      setTable('');
      setEmail('');
    } catch (error) {
      console.error('Erreur lors de l\'ajout du document: ', error);
      setErreur('Erreur lors de l\'ajout du document.');
    }
  };

  return (
    <div>
      {success ? (
        <h2>Maintenant, c'est le temps de commander</h2>
      ) : (
        <>
          <h2>Formulaire d'inscription</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label>
                Prénom:
                <input
                  type="text"
                  value={prenom}
                  onChange={(e) => setPrenom(e.target.value)}
                  required
                />
              </label>
            </div>
            <div>
              <label>
                Numéro de Table (1-100):
                <input
                  type="number"
                  value={table}
                  onChange={(e) => setTable(e.target.value)}
                  required
                />
              </label>
            </div>
            <div>
              <label>
                Email:
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </label>
            </div>
            {erreur && <p style={{ color: 'red' }}>{erreur}</p>}
            <button type="submit">Continuer</button>
          </form>
        </>
      )}
    </div>
  );
};

export default Formulaire;
