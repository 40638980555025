// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCtJ9McXzynWzL1_NsNdi6a0uHo4x1qSY8",
  authDomain: "skaneat-co.firebaseapp.com",
  projectId: "skaneat-co",
  storageBucket: "skaneat-co.appspot.com",
  messagingSenderId: "589538193126",
  appId: "1:589538193126:web:120894e088dd7717ee9527",
  measurementId: "G-88JR932EJM"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
